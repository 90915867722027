<template>
  <!-- no slider here -->
  <div class="d-flex flex-column">
    <!-- now when u save the page, it will format the code... -->
    <div style="margin-bottom: 30px" class="d-flex justify-center">
      <router-link to="/">
        <v-img
          lazy-src="../assets/heb.png"
          src="../assets/heb.png"
          max-height="80px"
          max-width="80px"
          margin-bottom="100px;"
      /></router-link>

      <router-link to="/french">
        <v-img
          lazy-src="../assets/fr.png"
          src="../assets/fr.png"
          max-height="80px"
          max-width="80px"
          margin-bottom="100px;"
      /></router-link>

      <router-link to="/spain">
        <v-img
          lazy-src="../assets/spain.png"
          src="../assets/spain.png"
          max-height="80px"
          max-width="80px"
          margin-bottom="100px;"
      /></router-link>

      <router-link to="/russia">
        <v-img
          lazy-src="../assets/russia.png"
          src="../assets/russia.png"
          max-height="80px"
          max-width="80px"
          margin-bottom="100px;"
      /></router-link>
      <router-link to="/german">
        <v-img
          lazy-src="../assets/ger.png"
          src="../assets/ger.png"
          max-height="80px"
          max-width="80px"
          margin-bottom="100px;"
      /></router-link>
    </div>

    <div class="d-flex flex-column justify-center align-center">
        <v-row class="div-top">
      
          <v-img
            lazy-src="../assets/achdut.png"
            src="../assets/achdut.png"
            max-height="100px"
            max-width="100px"
            margin-bottom="50px;"
          />
    
          <v-img
            lazy-src="../assets/reshit.png"
            src="../assets/reshit.png"
            max-height="55px"
            max-width="200px"
            margin-bottom="30px;"
          />
       
          <v-img
            lazy-src="../assets/moreshet.png"
            src="../assets/moreshet.png"
            max-height="300px"
            max-width="200px"
            padding-top="25px;"
          />
        
          <v-img
            lazy-src="../assets/olamshalem.png"
            src="../assets/olamshalem.png"
            max-height="100px"
            max-width="100px"
            margin-bottom="50px;"
          />

            <v-img
            lazy-src="../assets/eastJerusalem.png"
            src="../assets/eastJerusalem.png"
            max-height="60px"
            max-width="180px"
      
          />

      
      </v-row>
      <div class="stroke"></div>
      <div class="d-flex flex-column justify-center align-center">
        <v-col>
          <svg height="7" width="100%">
            <g stroke="#F8D766" justify="center" align="center">
              <path stroke-width="5" d="M20 5 l300 0" />
            </g>
          </svg>
        </v-col>
      </div>

      <v-img>
        <v-col>
          <div class="img-link">
            <a
              href="http://unityprize.org/%d7%99%d7%95%d7%9d-%d7%94%d7%90%d7%97%d7%93%d7%95%d7%aa/%d7%90%d7%95%d7%93%d7%95%d7%aa-%d7%99%d7%95%d7%9d-%d7%94%d7%90%d7%97%d7%93%d7%95%d7%aa/"
              target="_blank"
            >
              <v-img src="../assets/header_eng.png" alt="header" />
            </a>
          </div>
        </v-col>
      </v-img>
      <div
        style="
          font-size: 30px;
          color: #20a187;
          font-family: rubik;
          font-weight: bold;
          margin-top: 10px;
        "
        class="d-flex justify-center align-center wrap"
      >
        <h3>An interactive activity for youth groups and high school</h3>
      </div>

      <!--  </div>-->
      <!--<v-col>
      <div
        style="margin-top: 5%"
        class="d-flex justify-center align-center wrap goldback"
      >
        >
        <iframe
          width="853"
          height="480"
          src="https://www.youtube.com/embed/0Qv-6fQBFfA"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </v-col>
-->
      <div
        style="
          font-size: 22px;
          color: #20a187;
          font-family: rubik;
          font-weight: bold;
          margin-top: 2%;
        "
        class="d-flex justify-center align-center wrap"
      ></div>
      <v-col>
        <div class="goldback">
          <h3 class="text-center">Opening video</h3>
          <div class="d-flex justify-center align-center wrap">
            <v-col> </v-col>
            <v-col>
              <iframe
                width="704"
                height="396"
                src="https://www.youtube.com/embed/3KfhpIqaOPw"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe
              >‏
            </v-col>
            <v-col>
              <div
                class="button-download align-center justify-center flex-column desktop"
                style="margin-left: 1%"
              >
                <router-link
                  to="file_eng.pdf"
                  target="_blank"
                  style="color: white; text-decoration: none; font-size: 22px"
                >
                  <a src="file_eng.pdf" />
                  Insrtuctions for the teacher

                  <br />
                  <v-icon style="font-size: 40px; color: white"
                    >mdi-download</v-icon
                  >
                </router-link>
                <!-- <a
                  style="color: white; text-decoration: none; font-size: 22px"
                  href="file_eng.pdf"
                  download
                >
                  Insrtuctions for the teacher
                  <br />
                  <v-icon style="font-size: 40px; color: white"
                    >mdi-download</v-icon
                  >
                </a>-->
              </div>
            </v-col>
          </div>
        </div>
      </v-col>

      <div
        class="button-download align-center justify-center flex-column mobile"
        style="margin-left: 1%"
      >
        <router-link
          to="file_eng.pdf"
          target="_blank"
          style="color: white; text-decoration: none; font-size: 15px"
        >
          <a src="file_eng.pdf" />
          Insrtuctions for the teacher

          <br />
          <v-icon style="font-size: 40px; color: white">mdi-download</v-icon>
        </router-link>
      </div>
      <v-row>
        <v-col xl="6">
          <a
            href="https://closeapp.co.il/apps/games/jeru/v2/?id=-MZtVxYnQ3VJB_aidDXT"
            target="_blank"
          >
            <v-img src="../assets/game_eng1.png" alt="game1" />
          </a>
        </v-col>
        <v-col xl="6">
          <a
            href=" https://closeapp.co.il/racheli/cardGameV2/index.html"
            target="_blank"
          >
            <v-img src="../assets/game_eng2.png" alt="game2" />
          </a>
        </v-col>
      </v-row>
      <div
        style="
          font-size: 22px;
          color: #20a187;
          font-family: rubik;
          font-weight: bold;
          margin-top: 2%;
        "
        class="d-flex justify-center align-center wrap"
      ></div>

      <v-col>
        <div class="goldback">
          <h3 class="text-center" style="padding: 12px">A video for summary</h3>
          <div class="d-flex justify-center align-center wrap">
            <iframe
              width="704"
              height="396"
              src="https://www.youtube.com/embed/gJLYfnqcl6M"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe
            >‏
          </div>
        </div>
      </v-col>

      <!--<div class="justify-center align-center wrap games">
      <v-row>
        <v-img src="../assets/game1.png" alt="game1" />

        <v-img src="../assets/game2.png" alt="game2" />
       
      </v-row>
    </div>-->
      <!-- <v-carousel>
      <v-carousel-item
        v-for="(item, i) in items"
        :key="i"
        :src="item"
        reverse-transition="fade-transition"
        transition="fade-transition"
      >
      </v-carousel-item>
    </v-carousel> -->

      <v-img src="../assets/jerusalem.png" alt="jerusalem" />
      <div
        style="
          font-size: 30px;
          color: #20a187;
          font-family: rubik;
          font-weight: bold;
          margin-top: 15px;
        "
        class="d-flex justify-center align-center wrap"
      >
        <h3>Our partners</h3>
      </div>

      <!-- <div class="logos">-->
      <v-row class="partner">
        <v-col>
          <v-img src="../assets/olamshalem2.png" alt="olamshalem2" />
        </v-col>
        <v-col>
          <v-img src="../assets/boys_2.png" alt="boys_logo" />
        </v-col>
        <v-col>
          <v-img src="../assets/moreshet.png" alt="moreshet" />
        </v-col>
      </v-row>
      <v-row class="partner">
        <v-col>
          <v-img src="../assets/pmj.png" alt="pmj" />
        </v-col>
        <v-col>
          <v-img src="../assets/reshit.png" alt="reshit" />
        </v-col>
        <v-col>
          <v-img src="../assets/closeApp.png" alt="closeApp" />
        </v-col>
        <v-col>
          <v-img src="../assets/lifshitz.png" alt="lifshitz" />
        </v-col>
      </v-row>

      <!--</div>-->

      <!-- <v-row>
      <v-img src="../assets/footer2.png" alt="footer" />
    </v-row>-->

      <v-img src="../assets/footer2.png" alt="footer" />
    </div>
  </div>
</template>
<script>
export default {
  name: "Main",
  data() {
    return {
      items: [`${require("@/assets/jerusalem.jpg")}`],
    };
  },
  props: {},
  components: {},
  methods: {},
  mounted: {},
};
</script>
<style lang="scss" scoped>
.d-flex {
  display: flex;
  margin-top: 0px;
  //gap: 50px;
  display: inline-flex;
  margin-bottom: 0%;
}
.img-link {
  display: flex;
  justify-content: center;
  display: block;
  max-width: 1200px;
}

.stroke {
  fill: none;
  stroke: #646464;
  stroke-width: 1px;

  stroke-dasharray: 2, 2;
  stroke-linejoin: round;
}

/*.image {
  margin-bottom: 50px;
  margin-top: 0px;
}*/
.header {
  margin-top: 3%;
}

.button-download {
  background-color: #20a187;
  color: white;
  padding: 20px;
  text-align: center;

  //   display: inline-block;
  font-size: 30px;
  font-family: rubik;
  font-weight: bold;
  margin-bottom: 150px;
  height: 200px;
  width: 200px;
  border-radius: 50%;
  margin-top: 5%;
}
/*.a {
  font-size: 10px;
}*/
h3 {
  font-family: rubik;
  color: #20a187;
  font-size: 30px;
  font-weight: bold;
  align-content: center;
}

.p {
  font-family: rubik;
  color: #20a187;
  font-size: 24px;
  margin-bottom: 0%;
  align-content: center;
}
.logo-header {
  display: flex;
  // height: 40em;
  max-width: 2000em;

  margin-top: 0px;
  margin-bottom: 3%;
}
.v-carousel {
  display: flex;
  height: 800px;
  width: 2000px;
  margin-top: 0%;
}

.goldback {
  background-image: url("../assets/gold-background.png");
  background-size: cover;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-bottom: 2%;
  margin-top: 0%;
}
/*.header-image {
  background-image: url("../assets/header.png");
  background-size: cover;
  max-width: 1800px;
  height: 800px;
}*/
.logos {
  display: flex;
  flex-direction: row column column-reverse;
}

.mobile {
  display: none;
}

@media (max-width: 800px) {
  .row {
    width: 100%;
  }
  .desktop {
    display: none;
  }
  .mobile {
    display: block;
  }
  h3 {
    font-family: rubik;
    color: #20a187;
    font-size: 8vw;
    font-weight: bold;
    direction: rtl;
    text-align: center;
  }

  iframe {
    margin-top: 0%;
    margin-bottom: 0%;
    width: 320px;
    height: 200px;
  }

  .goldback {
    padding-top: 20px;
    padding-bottom: 20px;
    margin-bottom: 0;
    margin-top: 0;
  }
  .button-download {
    margin: 0;
    font-size: 5vw;
    height: 150px;
    width: 150px;
    margin-bottom: 20px;
  }
  .v-carousel {
    height: 300px !important;
    width: 100%;
  }
  .partner .v-image {
    width: 100px;
    margin: 0 auto;
  }

  .div-top .v-image {
    height: 80px;
    width: auto;
    margin-top: 15px;
    margin-left: 15px;

  }
}
</style>