<template>
  <v-app>
    <div id="nav"></div>
    <router-view />
    <!-- lets use this page -->
    <!--<v-btn>
      my button
      <v-icon>mdi-delete</v-icon>
    </v-btn>
-->
  </v-app>
</template>






<script>
export default {
  name: "App",
  data: () => ({
    model: 0,
    items: [
      {
        alt: "gold-background",
        img: "../assets/jerusalem.jpg",
        // src:"../assets/gold-background.png" , alt: "fff"
      },
      {
        src: "https://cdn.vuetifyjs.com/images/carousel/sky.jpg",
      },
      {
        src: "https://cdn.vuetifyjs.com/images/carousel/bird.jpg",
      },
      {
        src: "https://cdn.vuetifyjs.com/images/carousel/planet.jpg",
      },
    ],
  }),
};
</script>
