import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'
import Main from "../views/Main.vue"
import english from "../views/english.vue"
import french from "../views/french.vue"
import russia from "../views/russia.vue"
import spain from "../views/spain.vue"
import german from "../views/german.vue"

import VueFriendlyIframe from 'vue-friendly-iframe';

import testing from '../views/testing'

Vue.use(VueFriendlyIframe);

Vue.use(VueRouter)

const routes = [
    // {
    //   path: '/',
    //   name: 'Home',
    //   component: Home
    // },
    {
        path: '/',
        name: 'Main',
        component: Main
    },
    {
        path: '/english',
        name: 'english',
        component: english
    },
    {
        path: '/russia',
        name: 'russia',
        component: russia
    },
    {
        path: '/spain',
        name: 'spain',
        component: spain
    },
    {
        path: '/french',
        name: 'french',
        component: french
    },
    {
        path: '/german',
        name: 'german',
        component: german

    },
    {
        path: '/about',
        name: 'About',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/About.vue')
    },
    {
        path: '/testing',
        name: 'testing',
        component: testing
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router