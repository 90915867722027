<template>
  <!-- ok so this is the page element i just change its with and height to take all the screen -->
  <div
    class="d-flex justify-center align-center"
    style="height: 100vh; width: 100vw; background-color: yellowgreen"
  >
    <div class="ness"></div>
    <div class="ness"></div>
    <div class="ness"></div>

    <div class="flex-child d-flex justify-center align-center">
      <div class="ness2"></div>
      <div class="ness2"></div>
      <div class="ness2"></div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Testing",
};
//ok add it to the router and let me see the result on chrome
</script>
<style>
.ness {
  width: 150px;
  height: 150px;
  background-color: blue;
  margin: 5px;
}
.flex-child {
  width: 50%;
  height: 50%;
  background-color: purple;
}
.ness2 {
  width: 100px;
  height: 100px;
  background-color: red;
  margin: 5px;
}
</style>

